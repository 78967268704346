const sspRouter = {
  route: null,
  name: null,
  title: '流量管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont el-icon-suitcase',
  filePath: 'views/ssp/', // 文件路径
  order: 2,
  inNav: true,
  children: [
    {
      title: '账户管理',
      type: 'view',
      name: 'publisherList',
      route: '/publisher/list',
      filePath: 'views/ssp/publisher-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuan'
    },
    {
      title: '账户新增/编辑',
      type: 'view',
      name: 'publisherAdd',
      route: '/publisher/add',
      filePath: 'views/ssp/publisher-add.vue',
      inNav: false,
    },
    {
      title: 'APP管理',
      type: 'view',
      name: 'appList',
      route: '/app/list',
      filePath: 'views/ssp/app-list.vue',
      inNav: true,
      icon: 'iconfont el-icon-folder',
    },
    {
      title: 'APP新增/编辑',
      type: 'view',
      name: 'appAdd',
      route: '/app/add',
      filePath: 'views/ssp/app-add.vue',
      inNav: false,
    },
    {
      title: '广告位管理',
      type: 'view',
      name: 'placementListBasic',
      route: '/placement/list-basic',
      filePath: 'views/ssp/placement-list-basic.vue',
      inNav: true,
      icon: 'iconfont el-icon-files',
    },
    {
      title: '广告位添加',
      type: 'view',
      name: 'placementAdd',
      route: '/placement/add',
      filePath: 'views/ssp/placement-add.vue',
      inNav: false,
    },
    {
      title: '广告位-基础信息编辑',
      type: 'view',
      name: 'placementEditBasic',
      route: '/placement/editBasic',
      filePath: 'views/ssp/placement-edit-basic.vue',
      inNav: false,
    },
    {
      title: '广告位-价格设置',
      type: 'view',
      name: 'placementEditPrice',
      route: '/placement/editPrice',
      filePath: 'views/ssp/placement-edit-price.vue',
      inNav: false,
    },
    {
      title: '广告位价格',
      type: 'view',
      name: 'placementListPrice',
      route: '/placement/list-price',
      filePath: 'views/ssp/placement-list-price.vue',
      inNav: true,
      icon: 'iconfont el-icon-coin',
    },
  ],
}

export default sspRouter
