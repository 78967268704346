const billRouter = {
  route: null,
  name: null,
  title: '账单管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont el-icon-money',
  filePath: 'views/bill/', // 文件路径
  order: 4,
  inNav: true,
  children: [
    {
      title: '开发者账单',
      type: 'view',
      name: 'publisherBillList',
      route: '/publisherBill/list',
      filePath: 'views/bill/publisher-bill-list.vue',
      inNav: true,
      icon: 'iconfont el-icon-tickets'
    }
  ],
}

export default billRouter
