const bookRouter = {
  route: null,
  name: null,
  title: '数据报表',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tubiao',
  filePath: 'view/report/', // 文件路径
  order: 4,
  inNav: true,
  children: [
    {
      title: '流量维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/traffic',
      filePath: 'views/report/report-traffic.vue',
      inNav: true,
      icon: 'iconfont el-icon-data-line',
    },
    {
      title: '预算维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/budget',
      filePath: 'views/report/report-dsp.vue',
      inNav: true,
      icon: 'iconfont el-icon-coin',
    },
    {
      title: '小时维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/trafficHour',
      filePath: 'views/report/report-traffic-hour.vue',
      inNav: true,
      icon: 'iconfont el-icon-alarm-clock',
    },
    {
      title: '广告位维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/trafficPid',
      filePath: 'views/report/report-traffic-pid.vue',
      inNav: true,
      icon: 'iconfont el-icon-data-analysis',
    },
  ],
}

export default bookRouter
