import Utils from '@/lin/util/util'
import pluginsConfig from './plugin'
import sspConfig from './ssp'
import dspConfig from './dsp'
import reportConfig from './report'
import billRouter from './bill'

// eslint-disable-next-line import/no-mutable-exports
let homeRouter = [
  {
    title: '首页',
    type: 'view',
    name: Symbol('dashboard'),
    route: '/dashboard',
    filePath: 'views/dashboard.vue',
    inNav: true,
    icon: 'iconfont el-icon-s-home',
    order: 1,
  },
  {
    title: '个人中心',
    type: 'view',
    name: Symbol('center'),
    route: '/center',
    filePath: 'views/center.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
  },
  {
    title: '404',
    type: 'view',
    name: Symbol('404'),
    route: '/404',
    filePath: 'views/404.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
  },
  sspConfig,
  dspConfig,
  reportConfig,
  billRouter,
]

// 接入插件
const plugins = [...pluginsConfig]
filterPlugin(homeRouter)
//homeRouter = homeRouter.concat(plugins)

// 处理顺序
homeRouter = Utils.sortByOrder(homeRouter)
deepReduceName(homeRouter)

export default homeRouter

/**
 * 筛除已经被添加的插件
 */
function filterPlugin(data) {
  if (plugins.length === 0) {
    return
  }
  if (Array.isArray(data)) {
    data.forEach(item => {
      filterPlugin(item)
    })
  } else {
    const findResult = plugins.findIndex(item => data === item)
    if (findResult >= 0) {
      plugins.splice(findResult, 1)
    }
    if (data.children) {
      filterPlugin(data.children)
    }
  }
}

/**
 * 使用 Symbol 处理 name 字段, 保证唯一性
 */
function deepReduceName(target) {
  if (Array.isArray(target)) {
    target.forEach(item => {
      if (typeof item !== 'object') {
        return
      }
      deepReduceName(item)
    })
    return
  }
  if (typeof target === 'object') {
    if (typeof target.name !== 'symbol') {
      target.name = target.name || Utils.getRandomStr()
      target.name = Symbol(target.name)
    }

    if (Array.isArray(target.children)) {
      target.children.forEach(item => {
        if (typeof item !== 'object') {
          return
        }
        deepReduceName(item)
      })
    }
  }
}
